import { render, staticRenderFns } from "./CareerCoaching.vue?vue&type=template&id=44ba8bec&scoped=true"
import script from "./js/career-coaching.js?vue&type=script&lang=js&external"
export * from "./js/career-coaching.js?vue&type=script&lang=js&external"
import style0 from "./CareerCoaching.vue?vue&type=style&index=0&id=44ba8bec&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "44ba8bec",
  null
  
)

export default component.exports